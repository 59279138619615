.sortieTitle {
    width: 732.26px;
    height: 31.77px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 23.245px;
    line-height: 28px;

    color: #019BAB;
}

.sortieDescription {
    /* width: 732.26px;
    height: 19.37px; */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13.947px;
    line-height: 17px;

    /* Neutral / 500 */
    color: #64748B;
}

.ajoutsortie{
font-family: 'Montserrat';
font-style: normal;
background: #019BAB ;

}
.ajoutsortie:hover{
    background-color: #71717A !important;
    color:#fff
    
    }
.custom-table-column{
    width: 50px;
    height: 13px;
    font-style: normal;
    font-weight: 600;
    font-size: 13.8476px;
    line-height: 13px;
    color: #64748B;
}

/* detailSortie */
.nomme{
    font-weight: 600;
    /* width: 100px; */
    color: #333333;
    font-size: 13.53px;
}
.donnee{
    font-weight: 700;
    /* width: 100px; */
    color: black;
    font-size: 13.53px;
    display: block;
    margin-top: 10px;
}
.paragrap{
    font-weight: 600;
    margin-top: 47px;
}
.hr{
    background-color: #E7EBF4;
    border: none;
    height: 3px;
    margin-top: 5px;
}
@media screen and (max-width: 500px) {
    .rowsortie{
        display: flex;
        flex-direction: column;
    }
    .btns-sortie{
        /* padding:10px; */
        /* background-color: red; */
        width: 300px;
    }
}