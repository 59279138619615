.pic
{
     margin-top:10px; 
     width:110px;
     margin-bottom:-140px;
     margin-left: -40px;
}
.panel
{
    background-color: #01ADBA;
    border-top-right-radius: 15px;
    padding: 35px;
}

.name
{
    padding-left:150px;
    font-size:24px;
    font-weight: 700;
    color: #212B36;
    padding-top: 10px;
}
.bordure{
    border: 1px solid #E8EBED;
    margin-top: 10px;
    padding: 20px;
}
.profilDescription{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #64748B;
    margin-left: 100px;
}
.labels{
    color: 
    #212B36;
    font-size: 14px;
    font-weight: 500;
}
.inputes{
    font-size: 13px;
    font-weight: 500;
    color: #757575;
    /* padding: -5px; */
}