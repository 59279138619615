/* For the overlay */
.custom-modal-overlay-delete{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
}

/* For the modal */
.custom-modal-delete{
    position: fixed;
    width: 50%;
    height: 30%;
    left: 25%;
    top: 10%;
    border-radius: 10px;
    overflow: hidden;
    z-index: 99999; 
}
.custom-modal-delete *{
    overflow-x: hidden;
}
.custom-modal-delete header{
    background-color: rgba(67, 68, 69, 1);
    height: 40px;
    color: white;
    text-align: left;
    font-size: 80%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-weight: bold;
}
.custom-modal-delete .body{
    max-height: 100%;
    overflow-y: scroll;
    scrollbar-color: red yellow;
}

/* For scrollbar */
.custom-modal-delete.body::-webkit-scrollbar{
    background-color: transparent;
    width: 8px;
}
.custom-modal-delete .body::-webkit-scrollbar-thumb{
    background-color: rgba(180, 180, 180,0.8);
    border-radius: 5px;
}
.custom-modal-delete.body::-webkit-scrollbar-thumb:hover{
    background-color: #B4B4B4;
    border-radius: 5px;
}

/* Pour la responsivite */
@media screen and (max-width: 1200px) {
    .custom-modal-delete{
        width: 60%;
        height: 94%;
        left: 20%;
        top: 3%;
    }
}
@media screen and (max-width: 850px) {
    .custom-modal-delete{
        width: 70%;
        height: 94%;
        left: 15%;
        top: 3%;
    }
}
@media screen and (max-width: 600px) {
    .custom-modal-delete{
        width: 90%;
        height: 94%;
        left: 5%;
        top: 3%;
    }
}