.entreeTitle {
    width: 732.26px;
    height: 31.77px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 23.245px;
    line-height: 28px;

    color: #019BAB;
}

.entreeDescription {
    /* width: 732.26px;
    height: 19.37px; */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13.947px;
    line-height: 17px;

    /* Neutral / 500 */
    color: #64748B;
}

.ajoutEntree{
font-family: 'Montserrat';
font-style: normal;
background: #019BAB;

}
.ajoutEntree:hover{
    background-color: #71717A !important;
    color:#fff
}

/* .custom-table-column{
    width: 50px;
    height: 13px;
    font-style: normal;
    font-weight: 600;
    font-size: 13.8476px;
    line-height: 13px;
    color: #64748B;
    background-color: red ;
} */
.cardStyle{
    background-color: #fff;
    margin: auto;
    border-radius: 10px;
    border: none;
    position: relative;
    margin-bottom: 30px;
    /* height: 120px; */
    /* width: 280.95px; */
    box-shadow: 0 0.46875rem 1.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}
.btns-entree{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

option{
    width: 100% !important;
}

/* detailEntree */
.nomme{
    font-weight: 400;
    width: 100px;
    color: #333333;
    font-size: 3.53px;
}
.donnee{
    font-weight: 700;
    width: 100px;
    color: black;
    font-size: 13.53px;
    display: block;
    margin-top: 10px;
}
.donnees{
    font-weight: 700;
    width: 100px;
    color: black;
    font-size: 13.53px;
    margin-top: 10px;
}
.paragrap{
    font-weight: 600;
    margin-top: 47px;
}
.hr{
    background-color: #E7EBF4;
    border: none;
    height: 3px;
    margin-top: 5px;
}
/* CSS personnalisé pour la table et l'en-tête du filtre */
.table-wrapper {
    position: relative; /* Pour positionner les éléments enfants de manière absolue */
  }
.filter {
    width: 100px !important;
    padding: '5px' !important;
    font-size: '5px' !important;
}

  
@media screen and (max-width: 500px) {
    .rowEntree{
        display: flex;
        flex-direction: column;
    }
    .fileInput{
        margin: 0;
    }
}