
.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    
    /* padding: 0 10px;
    height: 90; */
    border-bottom: 1px solid rgba(0,0,0,0.1);
    background:#434445;
    /* margin-bottom: 0; */
}
.img{
    display: flex;
    align-items: center;
}
.img img{
    width: 45.86px;
    height: 45.86px;
}
.img h2{
    color: #ffffff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 19.5143px;
    line-height: 24px;
    text-align: center;
}
#user-dropdown, #messages-dropdown {
    background-color: transparent !important;
    color: #fff;
    border: none;
    box-shadow: none;
  }
  .badge-icon {
    font-size: 12px;
}
/* #alerte{
    height:'25px';
    display:'flex';
    align-items:'center';
    background:'#F0443A';
    border:'none';
    top:'3px';
    color:'#fff';
    font-weight:'600'
} */
.custom-alert {
    margin-top: 10px; /* Add some margin to separate it from other elements */
    border: 1px solid #dc3545; /* Add a border */
    background-color: #f8d7da; /* Set the background color */
    color: #721c24; /* Set the text color */
    padding: 12px; /* Add some padding to make it more visible */
    border-radius: 4px; /* Add rounded corners */
    font-weight: bold; /* Add bold font-weight */
  }
  .notification-icon {
    font-size: 12px;
    color: red;
    position: relative;
    top: -10px;
    left: 2px;
  }
  