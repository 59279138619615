.login-top-bar{
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.login-page{
    min-height: 100vh;
    background: linear-gradient(180deg, #008FA0 0%, #00BEC9 100%);

}
.login-hr{
    width: 12%;
    margin: auto !important;
    margin-top: -13px !important;
    border: 1px solid rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 50px;
}
.treso{
    margin-top: -15px;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 400;
}
.business{
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
}
.image-fewnu-login{
    width: 250px;
    height: 200px;
}
.login-input{
    border-radius: 7px;
    border: none;
    height: 42.09px;
    color: white;
    background-color: rgba(255, 255, 255, 0.5);
}
.login-input:focus{
    margin-top: 20px;
    width: 100%;
    border-radius: 7px;
    border: none;
    color: white;
    background-color: rgba(255, 255, 255, 0.5);
    /* border: #00BEC9 2px; */
}
.login-input::placeholder{
    color: white;
}
.login-btn{
    width: 100%;
    color: rgba(0, 190, 201, 1);
    font-weight: bold;
    border: none;
    
    height: 42.09px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3.74131px 3.74131px rgba(0, 0, 0, 0.3);
    border-radius: 9.35329px;
}
.lab{
    color: #fff;
    float: left;
}
.block-align{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.otp-hr{
    width: 45%;
    margin: auto !important;
    margin-top: -13px !important;
    margin-bottom: 13px !important;
    border: 1px solid rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 50px;
}

@media screen and (max-width: 500px) {
    .login-hr{
        width: 45%;
    }
}
