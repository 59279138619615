.table tr{
    background-color: transparent !important;
    vertical-align: middle;
}
.table tr td{
    font-size: 13px;
    background-color: transparent !important;
}
.table tr td p{
    margin: 0;
}
.table tr th{
    color: rgba(100, 116, 139, 1);
    font-size: 11px;
    font-weight: 600;
}
.table tr th{
    background-color: rgba(234, 236, 240, 1) !important;
}
.cardcss {
    background-color: #fff;
    margin: auto;
    border-radius: 10px;
    border: none;
    position: relative;
    margin-bottom: 30px;
    height: 90px;
    /* width: 280.95px; */
    box-shadow: 0 0.46875rem 1.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}
.titre-card{
    color: rgba(33, 43, 54, 1);
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 9px;
    /* line-height: 19.81px; */
}
.montant-card{
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 16.98px;
  line-height: 25.47px;
}
.pourcentage{
    color: #22C55E;
}
.pourcentage1{
    color: #EF4444;
}
.searcch{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4.29798px;
    /* gap: 7.75px; */
    background: #F7F8F9;
    border-radius: 3.09933px;
    width: 100%;
}
.buton{
    margin: 9px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6.19865px 12.3973px;
    gap: 7.75px;
    width: 78.94px;
    height: 30.99px;
    background: #FFFFFF;
    border:none;
    border-radius: 3.09933px;
}
.filtre{
    width: 34px;
    height: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12.3973px;
    line-height: 15px;
    color: #64748B;
}
.dash{
    width: 834.49px;
    height: 31.77px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 23.245px;
    line-height: 28px;
    color: #019BAB;
    margin-top: 20px;
}
.lorem{
    width: 834.49px;
    height: 19.37px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13.947px;
    line-height: 17px;
    color: #64748B;
}
.colors{
    width: 48px;
    height: 13px;
    font-style: normal;
    font-weight: 600;
    font-size: 13.8476px;
    line-height: 13px;
    color: #64748B;
}

.card .card-statistic-3 .card-icon-large .fas, .card .card-statistic-3 .card-icon-large .far, .card .card-statistic-3 .card-icon-large .fab, .card .card-statistic-3 .card-icon-large .fal {
    font-size: 110px;
}

.card .card-statistic-3 .card-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: -5px;
    top: 20px;
    opacity: 0.1;
}

.tab-line {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .tab-item {
    padding: 10px 20px;
    cursor: pointer;
    background-color: lightgray;
  }
  
  .tab-item.active {
    background-color: lightblue;
  }
  .voirPlus{
    font-family: 'Montserrat';
    font-style: normal;
    background: #019BAB;
    color: #fff;
    font-size: 12px;
    }
  .voirMoins{
    font-family: 'Montserrat';
    font-style: normal;
    background: #EF4444;
    color: #fff;
    font-size: 12px;
  }
    .voirPlus:hover{
        background-color: #71717A !important;
        color:#fff
    }
    .voirMoins:hover{
        background-color: #71717A !important;
        color:#fff
    }
    .cardBody{
      display: flex;
      align-items: center;
      justify-content: right;  
    }
  
    .DeuxDateFilter{
      display: flex;
      float: right;
      margin-top: 10px;
    }
/* ----------------------responsivite --------------- */
@media (max-width: 1400px) and (min-width:927px){
    .card-body {
      padding: 1rem;
    }
    
    .card-title {
      font-size: 0.1rem;
    }
    
    .card-body h5 {
      font-size: 1rem;
    }
    
    /* .pourcentage {
      font-size: 0.3rem;
    }
    .pourcentage1 {
        font-size: 0.3rem;
      } */
   
  }
  
  @media (max-width: 928px) and (min-width:576px) {
    
    .card {
      flex: 0 0 100%;
      margin-bottom: 1rem;
    }
    
    .card-body {
      padding: 1rem;
    }
    
    .card-title {
      font-size: 0.5rem;
    }
    
    .card-body h5 {
      font-size: 0.60rem;
    }
    
    .pourcentage {
      font-size: 0.3rem;
    }
    .pourcentage1 {
        font-size: 0.3rem;
      }
  }
  
 