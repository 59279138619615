       
.side{
    background-color: #F6F8F9;;
    height: 100%;
    padding: 25px 10px;
    margin-top: 0 ;
    border-right: 1px solid rgba(0,0,0,0.1);
}
.boutonDeconnect{
    width: 230px;
    height: 40.35544967651367px;
    border-radius: 8.87914752960205px;
    gap: 11.838862419128418px;
    background-color: #F0443A;
    border: none;
    color: #fff;
    font-weight: 600;
    /* margin-left: 10px; */
    }
.boutonDeconnect {
    display: flex;
    align-items: center;
    justify-content: center;
    }
    
    .boutonDeconnect img {
        width: 18px;
    }
.hrProfil{
    margin-top: 200px;
    border: none;
    height: 3px;
    background-color: #D0D5DD;
}
.profil_span{
    font-weight: 600;
    color: #64748B;
}

@media (min-width: 769px) and (max-width:900px){
    .mon-profil {
      width: 20%; 
    }
    .boutonDeconnect  {
        width: 55% !important;
        padding:26;
        background-color: transparent;
      }
    .visibles{
        display: none;
    }
    .profil_span{
        font-size: 8px;
        font-weight: 600;
    }
    .boutonDeconnect img{
        filter: invert(100%);
    }
  }

  @media (min-width: 280px) and (max-width: 768px) {
    .prof {
      width: 20%; 
    }
  
    .boutonDeconnect  {
      width: 55% !important;
      padding:26;
      background-color: transparent;
    }
    .boutonDeconnect img{
        filter: invert(100%);
    }
    .visibles{
        display: none;
    }
    .profil_span{
        font-size: 8px;
        font-weight: 600;
    }
  }
  
  /* Add more media queries for other screen sizes as needed */
  
      