.statDescription {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 13.947px;
  line-height: 17px;
  color: #64748B;
}
.custom-tab-list {
    padding: 6px;
  }
  .list-tab{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
}
ol, ul {
  padding-left: 0rem;
}
.selected-date select{
  border: none;
  background-color: transparent;
}
  
.custom-tab-list > .react-tabs__tab {
  padding: 0px 8px;
  margin: 2px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ddd;
  color: #333;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.custom-tab-list > .react-tabs__tab:hover {
  background-color: #aaa;
  color: #fff;
}

.custom-tab-list > .react-tabs__tab--selected {
  background-color: rgba(67, 68, 69, 1);
  color: #fff;
}
.chart-container {
  margin: auto;
}
ul{
  list-style: none;}

.chart-legend li span {
  display: inline-block;
  width: 10px;
  height: 10px;
  /* margin-right: 15px; */
}

.chart-legend li .square-entrees {
  background-color: rgba(34, 253, 150, 1);
}
  
.chart-legend li .square-sorties {
  background-color: rgba(221, 27, 27, 1);
}
.chartContainerPie {
  padding: 0; 
  /* width: 100%;
  height:'300px' */
}
.bar{
  text-align: center;
}
.select-container {
  display: flex;
  gap: 10px; /* Adjust the gap value to add the desired space between the two select elements */
}
/* Example CSS for custom-select class */
.custom-select {
  padding: 3px 16px;
  font-size: 16px; 
  border: 1px solid #ccc;
  border-radius: 4px; 
  background-color: transparent;
  color: #000; 
  margin-right: 10px; 
}
.chart-tab-panel {
  height: 400px;
  /* Ajoutez une barre de défilement si nécessaire */
  /* overflow-y: auto;  */
}
.chart-tab-panel .react-tabs__tab-panel {
  display: flex !important;
  flex-direction: column !important;
}
.chart-tab-panel .chart-container {
  flex: 1 !important;
  height: auto !important;
}

  

    
  
  