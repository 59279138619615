/* For the table */
.custom-bootstrap-table .table{
    display: block;
    min-width: 900px;
    overflow: scroll;
}
.custom-bootstrap-table .custom_table{
    /* display: flex; */
    overflow: hidden;
    background-color: white;
}
.custom-bootstrap-table .custom_table table{
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    margin-left: 13px;
    margin-right: 13px;
}
.custom-bootstrap-table .custom_table table thead tr{
    background-color: rgba(245, 245, 245, 0.35);
    color: #000;
}

.custom-bootstrap-table .custom_table tr:nth-child(even){
    background-color: rgba(245, 245, 245, 0.35);
}
.custom-bootstrap-table .custom_table tr{
    height: 60px;
}
table tbody tr{ cursor: pointer;}

/* For the Paginator */
.paginator {
    color: #000;
    font-size: 75%;
}
.paginator button {
    text-align: center;
}
.paginator .prev,.paginator .next{
    background-color: transparent;
    border: none;
    font-weight: 600;
}
.paginator .num{
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    font-weight: 600;
    padding: 0;
}
.paginator .num.actif{
    width: 20px;
    height: 20px;
    color: white;
    background-color: #019BAB;
    border: none;
    font-weight: 600;
    border-radius: 50%;
}

@media screen and (max-width: 768px) {
    .custom-bootstrap-table .table {
      min-width: 100%;
    }
  }